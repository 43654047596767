<template>
  <section class="products">
    <v-img
      v-if="info.banner"
      :src="info.banner"
      class="tnf-top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
        v-show="showCat"
      >
        <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <v-tabs grow light v-model="categoryTab">
              <v-tab v-for="tab of categorys" :key="tab.key">
                <a :href="'/products/'+tab.id">{{tab.name}}</a>
              </v-tab>
            </v-tabs>
        </v-flex>
      </v-layout>
    </v-img>
    <v-container grid-list-md text-center>
      <template>
        <div class="container">
          <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
        </div>
      </template>
      <v-container fluid grid-list-sm mt-4 mb-12 >
        <v-layout wrap v-if="detail" class="text-left">
          <!--<h3 style="text-align: left;margin: 30px 0;">{{detail.title}}</h3>-->
          <v-flex xs12 sm12 md12 lg12 class="product-title">
            {{detail.title}}
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6>
            <v-layout wrap>
              <template v-for="attr in detail.attr">
                <v-flex xs12 sm12 md12 lg12 mt-6 mb-2 :key="'l' + attr.id">
                  <h3 class="product-label">{{attr.attr_name}}</h3>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 :key="'v' + attr.id" class="product-value">
                  <div  v-html="attr.attr_value">
                    {{attr.attr_value}}
                  </div>
                </v-flex>
              </template>
              <v-flex xs12 sm12 md12 lg12 mt-6 mb-2>
                <h3 class="product-label">参数 & 性能</h3>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 class="product-value">
                <v-dialog
                  v-model="showParams"
                  scrollable
                  max-width="1100"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="show-dia-btn" color="#fff" depressed v-bind="attrs" v-on="on" >查看相关参数和性能</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="dia-title">
                      <v-layout wrap>
                        <v-flex xs10 sm10 md10 lg10 xl10>
                          <span>{{detail.title}}</span>
                        </v-flex>
                        <v-flex xs2 sm2 md2 lg2 xl2 class="text-right">
                          <v-btn icon dark @click="showParams = false" >
                            <v-icon color="#000">mdi-close</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div  v-html="detail.content" class="params-content">
                        {{detail.content}}
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 xl6 v-if="detail.imgs">
            <v-layout wrap>
              <v-flex xs12 sm12 md12 lg12 xl12>
                <v-img contain position="center center" height="285" :src="bigImg" ></v-img>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 xl12>
                <template>
                  <v-tabs
                    class="product-slider"
                    background-color="light accent-4"
                    center-active
                    centered
                    light
                    hide-slider
                    v-model="activeImg"
                    ref="activeImg"
                  >
                    <v-tab v-for="(img, ii) in detail.imgs" :key="ii" @click="changeBigImg(img.img)">
                      <img height="112" :src="img.img"/>
                    </v-tab>
                  </v-tabs>
                </template>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 2,
    category: null,
    categorys: [],
    categoryTab: null,
    catIds: [],
    catId: null,
    showCat: false,
    detail: null,
    activeImg: null,
    bigImg: null,
    breadcrumbs: [],
    showParams: false
  }),
  created () {
    this.getProduct()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banner = val.banner[this.pageId][0].img
        let catIds = []
        let categorys = []
        let cats = val.product.category.cn
        for (let i in cats) {
          catIds.push(cats[i].id)
          categorys.push(
            { key: i, id: cats[i].id, name: cats[i].name }
          )
        }
        this.catIds = catIds
        this.categorys = categorys
      },
      deep: true
    },
    detail: {
      handler: function (val, oldVal) {
        this.breadcrumbs.push({ text: val.title, disabled: true, href: '' })
        if (this.innerWidth < 550) {
          let imgs = document.querySelectorAll('.ctk-news-content img')
          imgs.forEach((img, i) => {
            img.classList.add('full-img')
          })
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banner = null
      if (this.$store.getters.companyInfo.banner) {
        banner = this.$store.getters.companyInfo.banner[this.pageId][0].img
      }
      return {
        banner: banner
      }
    }
  },
  methods: {
    getProduct () {
      this.https.get('product', { id: this.$route.params.id }).then(response => {
        if (response.code === 0) {
          this.detail = response.data
          document.title = this.detail.title + '_' + 'TRYBA特诺发|法式优雅，德国工艺——缔造完美门窗系统'
          this.catId = this.detail.cid
          this.getCategory(this.catId)
          if (this.detail.imgs[0]) {
            this.bigImg = this.detail.imgs[0].img
          }
        }
      })
    },
    getCategory (cid) {
      this.https.get('product_category', { id: cid }).then(response => {
        if (response.code === 0) {
          this.category = response.data
          if (this.category.cat.banner) {
            this.banner = this.category.cat.banner
          } else {
            this.banner = this.$store.getters.companyInfo.banner[this.pageId][0].img
          }
          if (this.category.pcat) {
            this.categoryTab = this.catIds.indexOf(parseInt(this.category.pcat.id))
          } else {
            this.categoryTab = this.catIds.indexOf(parseInt(this.category.cat.id))
          }
          this.showCat = true
          this.parseBreadcrumbs(this.category)
        }
      })
    },
    parseBreadcrumbs (category) {
      this.breadcrumbs = [
        { text: '首页', disabled: false, href: '/' }
      ]
      if (category.pcat) {
        this.breadcrumbs.push({ text: category.pcat.name, disabled: false, href: '/products/' + category.pcat.id })
        this.breadcrumbs.push({ text: category.cat.name, disabled: false, href: '/products/' + category.pcat.id + '/' + category.cat.id })
      } else {
        this.breadcrumbs.push({ text: category.cat.name, disabled: false, href: '/products/' + category.cat.id })
      }
      this.breadcrumbs.push({ text: this.detail.title, disabled: true })
    },
    changeBigImg (img) {
      this.bigImg = img
    }
  }
}
</script>
<style lang="css">
  @import '../css/product_detail.css';
</style>
